import React from "react";
import styled from "styled-components";
import cx from "classnames";
import PropTypes from "prop-types";

const directionClass = (props) => {
  switch(props.from) {
    case "top":
      return cx(props.visible ? "translate-y-0" : "-translate-y-full", "left-0", "top-0");
    case "right":
      return cx(props.visible ? "translate-x-0" : "translate-x-full", "right-0", "top-0");
    case "bottom":
      return cx(props.visible ? "translate-y-0" : "translate-y-full", "left-0", "bottom-0");
    default: 
      return cx(props.visible ? "translate-x-0" : "-translate-x-full", "left-0", "top-0");
  }
}

const heightClass = (props) => {
  switch(props.from) {
    case "left": 
    case "right":
      return "h-screen";
    default: return null;
  }
};

const widthClass = (props) => {
  switch(props.from) {
    case "top": 
    case "bottom":
      return "w-screen max-w-full";
    default: return null;
  }
};

const Styled = styled.div.attrs((props) => ({
  className: cx(
    "transform",
    "transition-all",
    "duration-200",
    "ease-in-out",
    "shadow-m",
    "fixed",
    "bg-white",
    "overflow-scroll",
    directionClass(props),
    heightClass(props),
    widthClass(props),
    props.className, 
  )
}))``;

export const SlidePanel = (props) => {
  return <Styled {...props} />
};

SlidePanel.propTypes = {
  from: PropTypes.oneOf(["left", "right", "bottom", "top"]),
  className: PropTypes.string,
  visible: PropTypes.bool,
}

SlidePanel.defaultProps = {
  from: "left",
  className: "",
  visible: false
};