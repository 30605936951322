import React from "react";
import cx from "classnames";
import styled from "styled-components";

import { useStrapiNavigationAction } from "../useStrapiNavigationAction";
import { Button } from "../../components/Button";

const IconWrapper = styled.span.attrs({
  className: "rounded-full overflow-hidden",
})`
  display: inline-block;
  vertical-align: middle;
  height: 1.5em;
  width: 1.5em;
  margin-right: 0.5em;
  img > {
    width: 100%;
    height: 100%;
  }
`

export const Action = ({ id, level, className, ...rest }) => {
  const action = useStrapiNavigationAction(id);

  const buttonType = action.style || action.color ? `${action.color || "primary"}-${action.style || "fill"}` : null;
  const icon = action.icon?.localFile?.publicURL && (
    <img src={action.icon?.localFile?.publicURL} alt={action.label.text} />
  );
  const props = {
    ...action.props,
    ...rest,
    className: cx("hover:underline", "block", "whitespace-nowrap", className),
  };
  
  const children = (
    <>
      {icon && <IconWrapper>{icon}</IconWrapper>}
      {action.label.text}
    </>
  );

  return buttonType ? <Button {...props}>{children}</Button> : <a {...props}>{children}</a>;
}