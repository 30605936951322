import React from "react"
import { TopBar } from "../components/TopBar"
import { useStaticQuery, graphql, Link } from "gatsby"

export const StrapiTopBar = ({ children }) => {
  const data = useStaticQuery(
    graphql`
      query TopBarQuery {
        strapi {
          topBar {
            logo {
              url
              localFile {
                publicURL
              }
            }
          }
        }
      }
    `
  )

  const logo = (
    <Link to="/">
      <img src={data.strapi?.topBar?.logo?.localFile?.publicURL} alt="Home" />
    </Link>
  )

  return <TopBar logo={logo}>{children}</TopBar>
}
