import React from "react";
import cx from "classnames";
import { Container } from "../Container";
import styled from "styled-components";
import { theme } from 'twin.macro'

const FooterContent = styled.div.attrs({
  className: "grid w-full"
})`

  grid: [row1-start] "logo social" auto [row1-end] / auto auto;

  @media (min-width: ${theme('screens.md')}) { 
    grid: [row1-start] "logo . links" auto [row1-end]
        [row2-start] "social . links" auto [row2-end]
        / auto auto auto;
  }
`;

export const Footer = React.forwardRef((props, ref) => {
  const { children, className, ...rest } = props;
  return (
    <footer ref={ref} {...rest} as="footer" className={cx("py-l", className)}>
      <Container>
        <FooterContent>{children}</FooterContent>
      </Container>
    </footer>
  );
})