import React from "react"
import cx from "classnames"
import { Container } from "../components/Container"
import { StrapiNavigationAction } from "./StrapiNavigationAction"
import { useBreakpoint } from "../util/useBreakpoint"
import breakpoints from "../breakpoints"
import { graphql, useStaticQuery } from "gatsby"

export const StrapiMobileTopAction = () => {
  const breakpoint = useBreakpoint(breakpoints)

  const data = useStaticQuery(graphql`
    query TopActionQuery {
      strapi {
        mobileNavigation {
          topAction {
            id
          }
        }
      }
    }
  `)

  if (breakpoint === "sm" || breakpoint === "md") {
    return (
      <div className={cx("w-full bg-gray-100 z-50")}>
        <Container className="flex py-s justify-end">
          <StrapiNavigationAction
            id={data.strapi.mobileNavigation.topAction.id}
          />
        </Container>
      </div>
    )
  }

  return null
}
