import { useStaticQuery, graphql } from "gatsby"

export const useStrapiFooter = () => {
  const data = useStaticQuery(graphql`
    query GetStrapiFooter {
      strapi {
        ...Footer
      }
    }
  `)

  return data.strapi.footer;
}