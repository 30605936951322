import React, { useEffect, useState } from "react"
import Helmet from "react-helmet"
import { useStrapiNavigationMenu } from "../strapi/useStrapiNavigationMenu"
import { useStrapiNavigationMenus } from "../strapi/useStrapiNavigationMenus"
import { useStrapiNavigationAction } from "../strapi/useStrapiNavigationAction"
import { useStrapiNavigationActions } from "../strapi/useStrapiNavigationActions"
import { navigate, graphql } from "gatsby"
import styled from "styled-components"
import { theme } from "twin.macro"
import { useIntl } from "../util/useIntl"
import { isSSR } from "../util/isSSR"
import cx from "classnames"
import { Chatbot } from "../strapi/Chatbot"
import * as constants from "../strapi/strapiComponentConstants"
import { Container } from "../components/Container"
import { Sidebar } from "../components/Sidebar"
import { StrapiSideMenu } from "../strapi/StrapiSideMenu"
import { Seo } from "../strapi/Seo"
import { StrapiMobileTopAction } from "../strapi/StrapiMobileTopAction"
import { StrapiFooter } from "../strapi/StrapiFooter"
import { StrapiTopBar } from "../strapi/StrapiTopBar"
import { StrapiNavigation } from "../strapi/StrapiNavigation"
import { GlobalStyles } from "../GlobalStyles"
import { DropDown } from "../components/DropDown"
import { usePreferredLanguage } from "../util/usePreferredLanguage"
import { PageContext } from "../contexts/PageContext"
import { SectionRenderer } from "../sections/SectionRenderer"

import "../styles.css"

const isBoolean = val => {
  if (typeof val === "boolean") {
    return true
  }
  return false
}

const DropDownMenuItem = ({ data, ...rest }) => {
  const action = useStrapiNavigationAction(data.id)
  const icon = action.icon?.localFile?.publicURL && (
    <img
      src={action.icon?.localFile?.publicURL}
      className="w-m h-m inline rounded-full"
      alt={action.label.text}
    />
  )

  return (
    <a className="hover:underline" {...rest} {...action.props}>
      {icon} {action.props.children}
    </a>
  )
}

const Content = styled.div`
  padding-top: ${props => (!props.$hasSideMenu ? "var(--navbar-height)" : 0)};
  @media (min-width: ${theme("screens.md")}) {
    padding-top: calc(var(--navbar-height) * 2);
  }
`

const DropDownMenuHeading = ({ data }) => {
  const { intl } = useIntl()

  const href = intl(data.link?.href).get()

  return href ? (
    <a className="hover:underline font-bold" href={href}>
      {intl(data.label).orPlaceholder()}
    </a>
  ) : (
    <span>{intl(data.label).orPlaceholder()}</span>
  )
}

const subMenuItems = (menuId, navigationMenus, navigationActions) => {
  const menu = navigationMenus.find(({ id }) => menuId === id)
  const href = menu.link?.href?.text

  return menu.children.reduce(
    (acc, c) => {
      const action = navigationActions.find(a => a.id === c.action?.id)
      if (action) {
        return [
          ...acc,
          {
            label: (
              <DropDownMenuItem data={action} style={{ paddingLeft: ".5em" }} />
            ),
            value: action?.id,
          },
        ]
      }
      return acc
    },
    [
      {
        label: <DropDownMenuHeading data={menu} />,
        value: "-",
        disabled: !href,
      },
    ]
  )
}

const DropDownMenu = ({ id, className, style }) => {
  const menu = useStrapiNavigationMenu(id)
  const menus = useStrapiNavigationMenus()
  const actions = useStrapiNavigationActions()

  const getLocation = () => {
    return !isSSR() ? window.location : undefined
  }

  const activeAction = actions.find(node => {
    if (node.location?.href?.text) {
      return (
        node.location.href.text.replace(/\/$/, "") ===
        getLocation()?.pathname.replace(/\/$/, "")
      )
    }
    return null
  })

  const items = menu.children.reduce((acc, child) => {
    switch (child.__typename) {
      case constants.NAVIGATION_ACTION_REFERENCE:
        return [
          ...acc,
          {
            label: <DropDownMenuItem data={child?.action} />,
            value: child?.action?.id,
          },
        ]
      case constants.NAVIGATION_MENU_REFERENCE:
        return [...acc, ...subMenuItems(child.menu.id, menus, actions)]
      default:
        return null
    }
  }, [])

  return (
    <DropDown
      style={style}
      className={className}
      value={activeAction?.id}
      items={items}
      renderOption={props => (
        <div
          className={cx([
            "px-m py-s w-full",
            props.disabled ? "bg-gray-100 text-gray-400" : "hover:bg-gray-100",
          ])}
          {...props}
        ></div>
      )}
    />
  )
}

export default function Page({ pageContext, data }) {
  const {
    navigateToPreferredLanguage,
    language,
    defaultLanguage,
    languageLinks,
  } = pageContext

  const hasSideMenu = !!data.strapi?.page?.sideMenu?.id

  const [navigationTransparent, setNavigationTransparent] = useState(true)

  const showNavigation = isBoolean(data.strapi?.page?.hideNavigation)
    ? !data.strapi.page.hideNavigation
    : true

  const showFooter = isBoolean(data.strapi?.page?.hideFooter)
    ? !data.strapi.page.hideFooter
    : true

  const [preferredLang] = usePreferredLanguage({
    defaultLanguage,
    languages: Object.keys(languageLinks),
  })

  useEffect(() => {
    setTimeout(() => {
      setNavigationTransparent(false)
    }, 100)
  }, [])

  useEffect(() => {
    if (preferredLang !== language && navigateToPreferredLanguage) {
      navigate(languageLinks[preferredLang])
    }
  }, [language, languageLinks, navigateToPreferredLanguage, preferredLang])

  const allSections = data.strapi.page?.sections || []

  const sections = allSections.map((data, index) => (
    <React.Fragment key={index}>
      <SectionRenderer data={data} />
    </React.Fragment>
  ))

  return (
    <PageContext.Provider value={{ ...pageContext, data }}>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
      </Helmet>
      <GlobalStyles />
      <Seo />
      {data.strapi.page?.enableChatbot && <Chatbot />}
      <div className="absolute top-0 flex flex-col w-full min-h-screen">
        {showNavigation && (
          <div
            className={cx(
              navigationTransparent ? "opacity-0" : "opacity-1",
              "z-50",
              "transition-opacity",
              "duration-200"
            )}
          >
            <StrapiMobileTopAction />
          </div>
        )}

        {showNavigation && (
          <div
            className={cx(
              "sticky top-0 z-50",
              navigationTransparent ? "opacity-0" : "opacity-1",
              "transition-opacity",
              "duration-200"
            )}
          >
            <StrapiTopBar>
              <StrapiNavigation />
            </StrapiTopBar>
          </div>
        )}

        <div className="flex flex-col flex-1 absolute w-full min-h-screen z-0 top-0">
          <Container
            className={cx(
              "flex flex-col flex-1 relative w-full z-0 mb-xl lg:flex-row lg:space-x-l",
              !hasSideMenu && "justify-center"
            )}
          >
            {hasSideMenu && (
              <Content
                className={cx(
                  "flex flex-col lg:block items-start w-full lg:w-1/4"
                )}
              >
                <DropDownMenu
                  id={data.strapi?.page?.sideMenu?.id}
                  className="w-full mt-s mb-m block lg:hidden"
                  style={{
                    maxHeight: "calc(100vh - var(--navbar-height) * 2)",
                    overflow: "auto",
                  }}
                />
                <Sidebar className="hidden lg:block">
                  <StrapiSideMenu id={data.strapi?.page?.sideMenu?.id} />
                </Sidebar>
              </Content>
            )}
            <Content
              $hasSideMenu={hasSideMenu}
              className={cx(
                "flex flex-col flex-1",
                !hasSideMenu && "items-center"
              )}
            >
              {sections}
            </Content>
          </Container>
          {showFooter && <StrapiFooter />}
        </div>
      </div>
    </PageContext.Provider>
  )
}

export const query = graphql`
  query BasicPageData($pageId: ID!) {
    strapi {
      page(id: $pageId) {
        ...Page
        enableChatbot
      }
    }
  }
`
