import React from "react"
import { useStrapiNavigationMenu } from "./useStrapiNavigationMenu"
import { useStrapiNavigationAction } from "./useStrapiNavigationAction"
import { useIntl } from "../util/useIntl"
import { Row, Column } from "../components"
import { NavigationLink } from "../components/Navigation"
import * as constants from "./strapiComponentConstants"

const NavigationLinkWrapper = ({ data = {}, ...rest }) => {
  const action = useStrapiNavigationAction(data.id)
  const icon = action.icon?.localFile?.publicURL && (
    <img src={action.icon?.localFile?.publicURL} alt={action.label?.text} />
  )

  return <NavigationLink icon={icon} {...rest} {...action.props} />
}

const NavigationSubMenu = ({ data = {} }) => {
  const menu = useStrapiNavigationMenu(data.id)
  const { intl } = useIntl()
  const columns =
    menu?.children.reduce(
      (acc, curr, index) => {
        const col = Math.floor(index / (menu.children.length / 2))
        acc[col].push(curr)
        return acc
      },
      [[], []]
    ) || []

  return (
    <>
      <NavigationLink href={intl(menu.link.href).get()}>
        {intl(menu.label).orPlaceholder()}
      </NavigationLink>
      <Row>
        {columns.map((links, index) => (
          <Column key={index} className="mr-s">
            {links.map(link => (
              <NavigationLinkWrapper
                level={2}
                data={link.action}
                key={link.action.id}
              ></NavigationLinkWrapper>
            ))}
          </Column>
        ))}
      </Row>
    </>
  )
}

export const StrapiNavigationMenu = ({ id }) => {
  const menu = useStrapiNavigationMenu(id)

  const children = menu.children.map(child => {
    switch (child.__typename) {
      case constants.NAVIGATION_ACTION_REFERENCE:
        return (
          child.action && (
            <NavigationLinkWrapper
              data={child.action}
              key={`action-${child.id}`}
            />
          )
        )
      case constants.NAVIGATION_MENU_REFERENCE:
        return <NavigationSubMenu data={child.menu} key={`menu-${child.id}`} />
      default:
        return null
    }
  })

  return <>{children}</>
}
