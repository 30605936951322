import React from "react";
import PropTypes from "prop-types";
import { isSSR } from "../../util/isSSR";
import { LoadingDropDown } from "./LoadingDropDown";

const LazyDropDown = React.lazy(() => import("./LiveDropDown").then((module) => ({ default: module.LiveDropDown })));

export const DropDown = (props) => {
  return !isSSR() ? (
    <React.Suspense fallback={(<LoadingDropDown {...props} />)}>
      <LazyDropDown {...props} />
    </React.Suspense>
  ) : (
    <LoadingDropDown {...props} />
  );
}

DropDown.propTypes = {
  renderOption: PropTypes.func,
};
DropDown.defaultProps = {
  renderOption: (props) => {
    return (
      <div {...props} />
    );
  }
}