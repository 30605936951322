import React from "react"
import * as constants from "./strapiComponentConstants"

import { useIntl } from "../util/useIntl"
import { StrapiLanguageSelect } from "./StrapiLanguageSelect"
import { StrapiNavigationAction } from "./StrapiNavigationAction"
import { useStrapiNavigationMenu } from "./useStrapiNavigationMenu"

import { NavigationMenuAnchor } from "../components/Navigation"

const StrapiNavigationMenuAnchor = ({ id, ...props }) => {
  const { intl } = useIntl()
  const menu = useStrapiNavigationMenu(id)

  return (
    <NavigationMenuAnchor {...props} href={intl(menu.link?.href).get()}>
      {intl(menu.label).orPlaceholder()}
    </NavigationMenuAnchor>
  )
}

export const StrapiNavigationGroupItemRenderer = ({ data = {}, ...props }) => {
  switch (data["__typename"]) {
    case constants.NAVIGATION_MENU_REFERENCE:
      return <StrapiNavigationMenuAnchor id={data.menu?.id} {...props} />
    case constants.NAVIGATION_LANGUAGE_SELECT_REFERENCE:
      return <StrapiLanguageSelect size="m" {...props} />
    case constants.NAVIGATION_ACTION_REFERENCE:
      return <StrapiNavigationAction id={data.action?.id} {...props} />
    default:
      return null
  }
}
