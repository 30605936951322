import React, { useReducer, useCallback, useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StrapiNavigationMenu } from "./StrapiNavigationMenu"
import useDebounce from "../util/useDebounce"
import * as constants from "./strapiComponentConstants"

import {
  Navigation,
  NavigationGroup,
  NavigationPopout,
} from "../components/Navigation"

import { StrapiNavigationGroupItemRenderer } from "./StrapiNavigationGroupItemRenderer"

const initialState = {
  menuId: undefined,
  overPopout: false,
  overAnchor: false,
}

const enteredAnchor = id => {
  return {
    type: "ENTERED_ANCHOR",
    payload: id,
  }
}

const leftAnchor = id => {
  return {
    type: "LEFT_ANCHOR",
    payload: id,
  }
}

const enteredPopout = () => {
  return {
    type: "ENTERED_POPOUT",
  }
}

const leftPopout = () => {
  return {
    type: "LEFT_POPOUT",
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "ENTERED_ANCHOR":
      return {
        ...state,
        menuId: action.payload,
        overAnchor: true,
      }
    case "LEFT_ANCHOR":
      return {
        ...state,
        overAnchor:
          action.payload !== state.activeAnchor ? false : state.activeAnchor,
      }
    case "ENTERED_POPOUT": {
      return {
        ...state,
        overPopout: true,
        overAnchor: false,
      }
    }
    case "LEFT_POPOUT": {
      return {
        ...state,
        overPopout: false,
      }
    }
    default:
      return state
  }
}

export const StrapiDesktopNavigation = () => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const showPopout = state.overPopout || state.overAnchor
  const popoutTargetRef = useRef()
  const data = useStaticQuery(
    graphql`
      query DesktopNavigationQuery {
        strapi {
          desktopNavigation {
            navigation_groups {
              name
              items {
                __typename
                ... on STRAPI_ComponentNavigationMenuReference {
                  id
                  menu {
                    id
                  }
                }
                ... on STRAPI_ComponentNavigationNavigationActionReference {
                  id
                  action {
                    id
                  }
                }
                ... on STRAPI_ComponentLanguageLangSelectReference {
                  id
                }
              }
            }
          }
        }
      }
    `
  )

  const handleEnterAnchor = useCallback(
    id => e => {
      popoutTargetRef.current = e.target
      dispatch(enteredAnchor(id))
    },
    []
  )

  const handleLeaveAnchor = useCallback(
    id => e => {
      dispatch(leftAnchor(id))
    },
    []
  )

  const handleEnterPopout = useCallback(e => {
    dispatch(enteredPopout())
  }, [])

  const handleLeavePopout = useCallback(e => {
    dispatch(leftPopout())
  }, [])

  const groups = data?.strapi?.desktopNavigation?.navigation_groups || []

  const renderedGroups = groups.map(group => (
    <NavigationGroup key={group.name}>
      {group.items.map(data => {
        if (data.__typename === constants.NAVIGATION_MENU_REFERENCE) {
          return (
            <StrapiNavigationGroupItemRenderer
              data={data}
              key={`${data.__typename}-${data.id}`}
              className="ml-s"
              onMouseOver={handleEnterAnchor(data.menu?.id)}
              onMouseLeave={handleLeaveAnchor(data.menu?.id)}
            />
          )
        }
        return (
          <StrapiNavigationGroupItemRenderer
            data={data}
            key={`${data.__typename}-${data.id}`}
            className="ml-s"
          />
        )
      })}
    </NavigationGroup>
  ))

  const popoutChildren = state.menuId ? (
    <StrapiNavigationMenu id={state.menuId} />
  ) : null

  const debouncedShow = useDebounce(showPopout, 25)

  return (
    <>
      <Navigation>{renderedGroups}</Navigation>
      <NavigationPopout
        show={debouncedShow}
        to={popoutTargetRef.current}
        placement="bottom"
        onMouseEnter={handleEnterPopout}
        onMouseLeave={handleLeavePopout}
      >
        {popoutChildren}
      </NavigationPopout>
    </>
  )
}
