import React from "react"
import {
  Collapsible,
  CollapsibleButton,
  CollapsibleContent,
} from "../../components/Collapsible"
import * as constants from "../strapiComponentConstants";
import { Action } from "./Action";
import { Row, Column } from "../../components";
import { useStrapiNavigationMenu } from "../useStrapiNavigationMenu";

const SubMenu = ({ id }) => {
  const menu = useStrapiNavigationMenu(id);

  const maxRows = Math.ceil(menu.children.length / 2);

  const columns = menu.children.reduce(
    (acc, curr, index) => {
      const col = Math.floor(index / maxRows);
      acc[col].push(curr)
      return acc
    },
    [[], [], []]
  );

  return (
    <>
      <div className="text-sm font-semibold">{menu.label.text}</div>
      <Row>
        {columns.map((links, index) => (
          <Column key={index} className="mr-s">
            {links.map(link => (
              <Action key={link.action.id} id={link.action.id} level={3} className="py-s" />
            ))}
          </Column>
        ))}
      </Row>
    </>
  )
}

export const MenuItem = ({ id, open, onChange }) => {
  const menu = useStrapiNavigationMenu(id);

  const children = menu.children.map(
    (child) => {
      switch(child.__typename) {
        case constants.NAVIGATION_ACTION_REFERENCE:
          return child.action && <Action id={child.action.id} key={child.id} level={2} className="py-s" />
        case constants.NAVIGATION_MENU_REFERENCE:
          return <SubMenu id={child.menu.id} key={child.id} />
        default: return null;
      }
    }
  );

  return (
    <Collapsible className="w-full" open={open} onChange={onChange}>
      <CollapsibleButton className={"font-semibold"}>{menu.label.text}</CollapsibleButton>
      <CollapsibleContent> 
        {children}
      </CollapsibleContent>
    </Collapsible>
  );
}