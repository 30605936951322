import React, { useCallback, useState } from "react";
import { Separator } from "../../components/Separator"
import { MenuItem, Action, } from "."; 
import * as constants from "../strapiComponentConstants"


export const Menu = ({ data }) => {
  const [openMenu, setOpenMenu] = useState(undefined);

  const toggleMenu = (index) => () => {
    setOpenMenu(cIndex => cIndex !== index ? index : undefined);
  };
  
  const renderMenuItem = useCallback((data, index) => {
    switch(data.__typename) {
      case constants.NAVIGATION_MENU_REFERENCE:
        return <MenuItem id={data.menu.id} open={index === openMenu} onChange={toggleMenu(index)} />
      case constants.NAVIGATION_ACTION_REFERENCE:
        return <Action id={data.action.id} key={data.action.id} className="font-semibold" />
      default: return null;
    }
  }, [openMenu]);
  
  const menuItems = data.map((data, index) => (
    <React.Fragment key={index}>
      {renderMenuItem(data, index)}
      <Separator />
    </React.Fragment>
  ))

  return menuItems;
}
