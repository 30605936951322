import React, { useMemo } from "react";
import { SelectedOption, Collapsed, ExpandIcon, normalizeItem } from "./common";

export const LoadingDropDown = (props) => {
  const { items, type, size, placeholder, className, style, value, renderOption } = props;

  const normalizedItems = useMemo(() => {
    return items.map(normalizeItem);
  }, [items]);

  const selectedItem = useMemo(() => {
    return normalizedItems.find((item) => {
      if(value && value.value) {
        return item.value === value.value;
      }
      return item.value === value;
    })
  }, [value, normalizedItems]);

  return (
    <Collapsed type="button" $size={size} $type={type} className={className} style={style}>
      <SelectedOption $type={type.type} $size={size} selected={selectedItem}>
        {renderOption({ 
          selected: true,
          children: selectedItem?.label || placeholder
        })}
      </SelectedOption>
      <ExpandIcon />
    </Collapsed>
  )
}

LoadingDropDown.propTypes = {

};

LoadingDropDown.defaultProps = {
  type: "primary-outline"
};