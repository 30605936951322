import React, { useState } from "react"
import cx from "classnames"
import { useStaticQuery, graphql } from "gatsby"
import { NavigationGroup } from "../components/Navigation"
import { MdClose } from "react-icons/md"
import {
  SlidePanel,
  SlidePanelHeading,
  SlidePanelBody,
} from "../components/SlidePanel"
import { Navigation } from "../components/Navigation"
import { CircleButton } from "../components/CircleButton"
import { Hamburger } from "../components/icons"
import { StrapiNavigationAction } from "./StrapiNavigationAction"
import * as StrapiMobileNavigation from "./StrapiMobileNavigation"
import { StrapiNavigationGroupItemRenderer } from "./StrapiNavigationGroupItemRenderer"

export const StrapiTabletNavigation = () => {
  const [panelVisible, setPanelVisible] = useState(false)
  const data = useStaticQuery(
    graphql`
      query TabletNavigationQuery {
        strapi {
          tabletNavigation {
            menuItems {
              __typename
              ... on STRAPI_ComponentNavigationMenuReference {
                id
                menu {
                  id
                }
              }
              ... on STRAPI_ComponentNavigationNavigationActionReference {
                id
                action {
                  id
                }
              }
            }
            navigation_groups {
              __typename
              items {
                ... on STRAPI_ComponentNavigationMenuReference {
                  id
                  menu {
                    id
                  }
                }
                ... on STRAPI_ComponentNavigationNavigationActionReference {
                  id
                  action {
                    id
                  }
                }
                ... on STRAPI_ComponentLanguageLangSelectReference {
                  id
                }
              }
              id
              name
            }
            menuBottomActions {
              __typename
              ... on STRAPI_ComponentNavigationNavigationActionReference {
                id
                action {
                  id
                }
              }
              ... on STRAPI_ComponentLanguageLangSelectReference {
                id
              }
            }
            menuTopAction {
              action {
                id
              }
              id
            }
          }
        }
      }
    `
  )

  const groups = data?.strapi?.tabletNavigation?.navigation_groups || []

  const renderedGroups = groups.map(group => (
    <NavigationGroup key={group.name}>
      {group.items?.map(data => (
        <StrapiNavigationGroupItemRenderer
          className="ml-s"
          data={data}
          key={data.id}
        />
      ))}
    </NavigationGroup>
  ))

  const topAction = data?.strapi?.tabletNavigation?.menuTopAction?.action

  return (
    <Navigation>
      {renderedGroups}
      <NavigationGroup>
        <CircleButton
          size="m"
          type="gray"
          onClick={() => setPanelVisible(true)}
        >
          <Hamburger />
        </CircleButton>
      </NavigationGroup>
      <SlidePanel from={"right"} visible={panelVisible}>
        <div className={cx("w-96", "h-screen")}>
          <SlidePanelHeading>
            <StrapiNavigationAction id={topAction.id} />
            <CircleButton
              size="m"
              className="ml-m"
              type="gray"
              onClick={() => setPanelVisible(false)}
            >
              <MdClose />
            </CircleButton>
          </SlidePanelHeading>
          <SlidePanelBody>
            <StrapiMobileNavigation.Menu
              data={data?.strapi?.tabletNavigation?.menuItems}
            />
            <div className="my-s flex justify-end">
              <StrapiMobileNavigation.BottomActions
                data={data?.strapi?.tabletNavigation?.menuBottomActions}
              />
            </div>
          </SlidePanelBody>
        </div>
      </SlidePanel>
    </Navigation>
  )
}
