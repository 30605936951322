import React, { useState, useEffect } from "react"
import cx from "classnames"
import { useStaticQuery, graphql } from "gatsby"
import { NavigationGroup } from "../../components/Navigation"
import { MdClose } from "react-icons/md"
import {
  SlidePanel,
  SlidePanelHeading,
  SlidePanelBody,
} from "../../components/SlidePanel"
import { Navigation } from "../../components/Navigation"
import { CircleButton } from "../../components/CircleButton"
import { Hamburger } from "../../components/icons"
import { StrapiNavigationAction } from "../StrapiNavigationAction"
import { Menu, BottomActions } from "."

export const StrapiMobileNavigation = () => {
  const [panelVisible, setPanelVisible] = useState(false)

  useEffect(() => {
    if (!document) return
    document.body.style.overflow = panelVisible ? "hidden" : "auto"
  }, [panelVisible])

  const data = useStaticQuery(
    graphql`
      query MobileNavigationQuery {
        strapi {
          mobileNavigation {
            id
            menuItems {
              __typename
              ... on STRAPI_ComponentNavigationMenuReference {
                id
                menu {
                  id
                }
              }
              ... on STRAPI_ComponentNavigationNavigationActionReference {
                id
                action {
                  id
                }
              }
            }
            menuBottomActions {
              __typename
              ... on STRAPI_ComponentNavigationNavigationActionReference {
                id
                action {
                  id
                }
              }
              ... on STRAPI_ComponentLanguageLangSelectReference {
                id
              }
            }
            topAction {
              id
            }
          }
        }
      }
    `
  )

  const topAction = data?.strapi?.mobileNavigation?.topAction

  return (
    <Navigation>
      <NavigationGroup>
        <CircleButton
          size="m"
          type="gray"
          onClick={() => setPanelVisible(true)}
        >
          <Hamburger />
        </CircleButton>
      </NavigationGroup>
      <SlidePanel from={"top"} visible={panelVisible}>
        <div className={cx("w-full", "h-screen", "overflow-scroll")}>
          <SlidePanelHeading className="justify-end">
            <StrapiNavigationAction id={topAction?.id} />
            <CircleButton
              size="m"
              className="ml-m"
              type="gray"
              onClick={() => setPanelVisible(false)}
            >
              <MdClose />
            </CircleButton>
          </SlidePanelHeading>
          <SlidePanelBody>
            <Menu data={data?.strapi?.mobileNavigation?.menuItems} />
            <BottomActions
              data={data?.strapi?.mobileNavigation?.menuBottomActions}
            />
          </SlidePanelBody>
        </div>
      </SlidePanel>
    </Navigation>
  )
}
