import styled, { css } from "styled-components";
import cx from "classnames";
import { MdExpandMore, MdExpandLess } from "react-icons/md";

const typeClasses = {
  primary: `bg-blue-500 text-white hover:shadow border-transparent`,
  secondary: `bg-green-500 hover:shadow border-transparent`,
  "primary-outline": `border-blue-500 bg-white hover:shadow hover:border-blue-600`,
  "secondary-outline": `border-green-500 bg-white hover:shadow hover:border-green-600`,
}

const optionTypeClasses = props => {
  const classes = {
    primary: props.$highlighted && "bg-blue-400",
    secondary: props.$highlighted && "bg-green-600",
    "primary-outline": props.$highlighted && `bg-gray-100`,
    "secondary-outline": props.$highlighted && `bg-gray-100`,
  };

  return classes[props.$type];
};

export const Option = styled.div.attrs(props => ({
  className: cx(
    props.className,
    "flex",
    "relative",
    "items-center",
    "justify-between",
    "w-full",
    "cursor-pointer",
    "border-transparent",
    optionTypeClasses(props),
    props.$selected && "font-bold",
  ),
}))`
  white-space: nowrap;
`;

export const SelectedOption = styled(Option).attrs(({
  className: "flex-1 font-bold text-left"
}))``;

export const Collapsed = styled.button.attrs(props => ({
  className: cx(
    props.className,
    typeClasses[props.$type],
    "inline-flex",
    "rounded-xl",
    "tracking-wider",
    "transition",
    "ease-in-out",
    "duration-200",
    "border-2",
    "overflow-hidden",
    "items-center"
  ),
}))`
  visibility: ${props => (props.isOpen ? "hidden" : "visible")};
`

export const Expanded = styled.div.attrs(props => ({
  className: cx(
    props.className,
    typeClasses[props.$type],
    "fixed",
    "rounded-xl",
    "tracking-wider",
    "border-2",
    "z-50",
    props.isOpen ? "visible" : "invisible",
  ),
}))`
  outline: none;
  overflow: hidden;
`

const iconStyles = css`
  display: inline-block;
  font-size: 1.5rem;
`

export const ExpandIcon = styled(MdExpandMore)`
  ${iconStyles}
`
export const CollapseIcon = styled(MdExpandLess)`
  ${iconStyles}
`

export const normalizeItem = item => {
  if (item.value && item.label) return item
  return {
    label: item,
    value: item,
  }
}