import React from "react"
import Helmet from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import { useIntl } from "../util/useIntl"

export const Chatbot = () => {
  const { intl } = useIntl()

  const data = useStaticQuery(graphql`
    query {
      strapi {
        config {
          chatbotScript {
            ...LanguageText
          }
        }
      }
    }
  `)

  const src = intl(data.strapi?.config?.chatbotScript).get()

  if (src) {
    return (
      <Helmet>
        <script async src={src} type="text/javascript" />
      </Helmet>
    )
  }

  return null
}
