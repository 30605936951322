import React from "react"
import cx from "classnames"
import { StrapiNavigationAction } from "../StrapiNavigationAction"
import { StrapiLanguageSelect } from "../StrapiLanguageSelect"
import * as constants from "../strapiComponentConstants"

export const BottomActions = ({ data, className, ...rest }) => {
  return (
    <div className={cx("my-s flex justify-end flex-wrap", className)} {...rest}>
      {data.map((item, index) => {
        switch (item.__typename) {
          case constants.NAVIGATION_ACTION_REFERENCE:
            return (
              <StrapiNavigationAction
                id={item.action.id}
                className="ml-s first:ml-0 mb-s"
                key={index}
              />
            )
          case constants.NAVIGATION_LANGUAGE_SELECT_REFERENCE:
            return (
              <StrapiLanguageSelect
                size="m"
                className="ml-s mb-s"
                key={index}
              />
            )
          default:
            return null
        }
      })}
    </div>
  )
}
