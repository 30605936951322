import React from "react"
import { StrapiDesktopNavigation } from "./StrapiDesktopNavigation"
import { StrapiTabletNavigation } from "./StrapiTabletNavigation"
import { StrapiMobileNavigation } from "./StrapiMobileNavigation"
import { useBreakpoint } from "../util/useBreakpoint"
import breakpoints from "../breakpoints"

export const StrapiNavigation = () => {
  const breakpoint = useBreakpoint(breakpoints)

  let el

  switch (breakpoint) {
    case "sm":
      el = <StrapiMobileNavigation />
      break
    case "md":
      el = <StrapiTabletNavigation />
      break
    default:
      el = <StrapiDesktopNavigation />
  }

  return el
}
