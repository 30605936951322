import React, { useCallback, useContext } from "react"
import { navigate } from "gatsby"
import { useStrapiLanguages } from "./useStrapiLanguages"
import { usePreferredLanguage } from "../util/usePreferredLanguage"
import { PageContext } from "../contexts/PageContext"
import { DropDown } from "../components/DropDown"

export const StrapiLanguageSelect = props => {
  const { language, languageLinks, defaultLanguage } = useContext(PageContext)

  const languages = useStrapiLanguages()
  const [, setPreferredLanguage] = usePreferredLanguage({
    languages,
    defaultLanguage,
  })

  const handleChange = useCallback(
    val => {
      const lang = val.selectedItem?.value?.toLowerCase()
      setPreferredLanguage(lang)
      navigate(languageLinks[lang])
    },
    [setPreferredLanguage, languageLinks]
  )

  const selected = languages.find(
    lang => lang.toLowerCase() === language.toLowerCase()
  )

  return (
    <DropDown
      items={languages}
      onChange={handleChange}
      value={selected}
      renderOption={(props) => (<div className="px-m py-xs w-full hover:bg-gray-100" {...props} />)}
      {...props}
    />
  )
}
